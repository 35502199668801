<template>
  <div id="app" class="crt">
    <vue-command
      :autocompletion-resolver="autocompletionResolver"
      :built-in="builtIn"
      :commands="commands"
      :cursor.sync="cursor"
      :history.sync="history"
      :help-timeout="1250"
      :prompt="prompt"
      :title="title"
      :stdin.sync="stdin"
      show-help
    >
    </vue-command>
  </div>
</template>

<script>
import VueCommand, {
  createStdout,
  createStderr,
  createDummyStdout,
} from "vue-command";
import "vue-command/dist/vue-command.css";

const PROMPT = "Terminal: ~/BUIDLCON/";

export default {
  name: "App",
  components: {
    VueCommand,
  },
  data: () => ({
    autocompletionResolver: () => undefined,
    builtIn: undefined,
    helpT: "Type help",
    commands: {
      // Navigate to home, self and back
      cd: undefined,
      // Clear terminals history
      clear: undefined,
      // Returns the parsed object to test parsing
      // E. g.: echo --x="one two three" --y="one two" --z="one" --test="okay" --x1 --y2 --t=ok -dash
      echo: (_) => createStdout(JSON.stringify(_, null, 2)),
      // Show help
      help: () =>
        createStdout(`
        <div class="ace">
        ██████╗ ██╗   ██╗██╗██████╗ ██╗      ██████╗ ██████╗ ███╗   ██╗    ██████╗  ██████╗ ██████╗ ██████╗ 
        ██╔══██╗██║   ██║██║██╔══██╗██║     ██╔════╝██╔═══██╗████╗  ██║    ╚════██╗██╔═████╗╚════██╗╚════██╗
        ██████╔╝██║   ██║██║██║  ██║██║     ██║     ██║   ██║██╔██╗ ██║     █████╔╝██║██╔██║ █████╔╝ █████╔╝
        ██╔══██╗██║   ██║██║██║  ██║██║     ██║     ██║   ██║██║╚██╗██║    ██╔═══╝ ████╔╝██║██╔═══╝ ██╔═══╝ 
        ██████╔╝╚██████╔╝██║██████╔╝███████╗╚██████╗╚██████╔╝██║ ╚████║    ███████╗╚██████╔╝███████╗███████╗
        ╚═════╝  ╚═════╝ ╚═╝╚═════╝ ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝    ╚══════╝ ╚═════╝ ╚══════╝╚══════╝
        </div>
        <br><br>                                                                                            
        Available programms:<br><br>
        &nbsp;soloenroll<br>
        &nbsp;teamenroll<br>
        &nbsp;teamup<br>
        &nbsp;twitter<br>
        &nbsp;prize<br>
        &nbsp;codeofconduct<br>
        &nbsp;sponsor<br><br>
      `),
      prize: () => createStdout(`🏆Prize TBA`),
      codeofconduct: () => createStdout(`📖Codeofconduct TBA`),
      sponsor: () =>
        createStdout(`BUDILCON 2022 sponsored by:<br><br>
        &nbsp;&nbsp;&nbsp;[+] <a href='https://rss3.io/' target="_blank">RSS3</a><br>
        &nbsp;&nbsp;&nbsp;[+] <a href='https://meson.network/' target="_blank">Meson Network</a><br>
        &nbsp;&nbsp;&nbsp;[+] <a href='https://cyberconnect.me/' target="_blank">CyberConnect</a><br>
        &nbsp;&nbsp;&nbsp;[+] <a href='https://www.gather.town/' target="_blank">Gather</a><br>
      `),
      teamup: () => undefined,
      // Show current path
      pwd: () => undefined,
    },
    // Terminal cursor position
    cursor: 0,
    history: [],
    options: {
      long: {
        pokedex: ["color"],
        loading: ["amount", "timeout"],
      },
      short: {
        pokedex: ["h"],
      },
    },

    // prompt: "Terminal: ~/BUIDLCON",
    title: "root@Terminal: ~/BUIDLCON/",
    prompt: PROMPT,
    stdin: "",
  }),
  created() {
    this.commands.clear = () => {
      this.history = [];
      // Push dummy Stdout to show Stdin
      return createDummyStdout();
    };
    this.commands.teamup = () => {
      window.open("https://rss3.notion.site");
      return createDummyStdout();
    };
    this.commands.twitter = () => {
      window.open("https://twitter.com/buidlcon_");
      return createDummyStdout();
    };
    this.commands.cd = ({ _ }) => {
      if ((_[1] === "home" || _[1] === "home/") && this.prompt === PROMPT) {
        this.prompt = `${PROMPT}home`;
        return createDummyStdout();
      }
      // Navigate from home to root
      if (
        (_[1] === "../" || _[1] === "..") &&
        this.prompt === `${PROMPT}home`
      ) {
        this.prompt = PROMPT;
        return createDummyStdout();
      }
      // Navigate to self
      if (_[1] === "." || _[1] === "./" || typeof _[1] === "undefined") {
        return createDummyStdout();
      }
      return createStderr(`cd: ${_[1]}: No such file or directory`);
    };
    this.commands.pwd = () => {
      // Take current prompt into account
      if (this.prompt === "~neil@moon:#/") {
        return createStdout("/");
      } else {
        return createStdout("/home");
      }
    };
    this.builtIn = (stdin, terminal) => {
      // Check for application
      if (stdin.trim().split(" ")[0] !== "reverse") {
        terminal.commandNotFound(stdin);
        return;
      }
      stdin = stdin.trim();
      // Get second argument
      const argument = stdin.split(" ").slice(1).join(" ").replace(/"/g, "");
      // Do nothing if no argument given
      if (!argument) {
        return;
      }
      // Reverse argument
      this.stdin = argument.split("").reverse().join("");
    };
    this.autocompletionResolver = () => {
      // Preserve cursor position
      const cursor = this.cursor;
      // Reverse concatenate autocompletable according to cursor
      let pointer = this.cursor;
      let autocompleteableStdin = "";
      while (this.stdin[pointer - 1] !== " " && pointer - 1 > 0) {
        pointer--;
        autocompleteableStdin = `${this.stdin[pointer]}${autocompleteableStdin}`;
      }
      // Divide by arguments
      const command = this.stdin.split(" ");
      // Autocompleteable is program
      if (command.length === 1) {
        const autocompleteableProgram = command[0];
        // Collect all autocompletion candidates
        const candidates = [];
        const programs = [...Object.keys(this.commands), "reverse"].sort();
        programs.forEach((program) => {
          if (program.startsWith(autocompleteableProgram)) {
            candidates.push(program);
          }
        });
        // Autocompletion resolved into multiple results
        if (this.stdin !== "" && candidates.length > 1) {
          this.history.push({
            // Build table programmatically
            render: (createElement) => {
              const columns = candidates.length < 5 ? candidates.length : 4;
              const rows =
                candidates.length < 5
                  ? 1
                  : Math.ceil(candidates.length / columns);
              let index = 0;
              const table = [];
              for (let i = 0; i < rows; i++) {
                const row = [];
                for (let j = 0; j < columns; j++) {
                  row.push(createElement("td", candidates[index]));
                  index++;
                }
                table.push(createElement("tr", [row]));
              }
              return createElement("table", { style: { width: "100%" } }, [
                table,
              ]);
            },
          });
        }
        // Autocompletion resolved into one result
        if (candidates.length === 1) {
          // Mutating Stdin mutates the cursor, so we've to wait to push it to the end
          const unwatch = this.$watch(
            () => this.cursor,
            () => {
              this.cursor =
                cursor +
                (candidates[0].length - autocompleteableStdin.length + 0);
              unwatch();
            }
          );
          this.stdin = candidates[0];
        }
        return;
      }
      // Check if option might be completed already or option is last tokens
      if (
        this.stdin[cursor] !== "" &&
        this.stdin[cursor] !== " " &&
        typeof this.stdin[cursor] !== "undefined"
      ) {
        return;
      }
      // Get the executable
      const program = command[0];
      // Check if any autocompleteable exists
      if (
        typeof this.options.long[program] === "undefined" &&
        typeof this.options.short[program] === "undefined"
      ) {
        return;
      }
      // Autocompleteable is long option
      if (autocompleteableStdin.substring(0, 2) === "--") {
        const candidates = [];
        this.options.long[program].forEach((option) => {
          // If only dashes are presents, user requests all options
          if (
            `--${option}`.startsWith(autocompleteableStdin) ||
            autocompleteableStdin === "--"
          ) {
            candidates.push(option);
          }
        });
        // Autocompletion resolved into one result
        if (candidates.length === 1) {
          const autocompleted = `${this.stdin.substring(0, pointer - 1)} --${
            candidates[0]
          }`;
          const rest = `${this.stdin.substring(this.cursor)}`;
          // Mutating Stdin mutates the cursor, so we've to wait to push it to the end
          const unwatch = this.$watch(
            () => this.cursor,
            () => {
              this.cursor =
                cursor +
                (candidates[0].length - autocompleteableStdin.length + 2);
              unwatch();
            }
          );
          this.stdin = `${autocompleted}${rest}`;
          return;
        }
        // Autocompletion resolved into multiple result
        if (autocompleteableStdin === "--" || candidates.length > 1) {
          this.history.push({
            // Build table programmatically
            render: (createElement) => {
              const columns = candidates.length < 5 ? candidates.length : 4;
              const rows =
                candidates.length < 5
                  ? 1
                  : Math.ceil(candidates.length / columns);
              let index = 0;
              const table = [];
              for (let i = 0; i < rows; i++) {
                const row = [];
                for (let j = 0; j < columns; j++) {
                  row.push(createElement("td", `--${candidates[index]}`));
                  index++;
                }
                table.push(createElement("tr", [row]));
              }
              return createElement("table", { style: { width: "100%" } }, [
                table,
              ]);
            },
          });
        }
        return;
      }
      // Autocompleteable is option
      if (autocompleteableStdin.substring(0, 1) === "-") {
        const candidates = [];
        this.options.short[program].forEach((option) => {
          // If only one dash is present, user requests all options
          if (
            `-${option}`.startsWith(autocompleteableStdin) ||
            autocompleteableStdin === "-"
          ) {
            candidates.push(option);
          }
        });
        // Autocompletion resolved into one result
        if (candidates.length === 1) {
          const autocompleted = `${this.stdin.substring(0, pointer - 1)} -${
            candidates[0]
          }`;
          const rest = `${this.stdin.substring(this.cursor)}`;
          // Mutating Stdin mutates the cursor, so we've to wait to push it to the end
          const unwatch = this.$watch(
            () => this.cursor,
            () => {
              this.cursor =
                cursor +
                (candidates[0].length - autocompleteableStdin.length + 1);
              unwatch();
            }
          );
          this.stdin = `${autocompleted}${rest}`;
          return;
        }
        // Autocompletion resolved into multiple result
        if (autocompleteableStdin === "-" || candidates.length > 1) {
          this.history.push({
            // Build table programmatically
            render: (createElement) => {
              const columns = candidates.length < 5 ? candidates.length : 4;
              const rows =
                candidates.length < 5
                  ? 1
                  : Math.ceil(candidates.length / columns);
              let index = 0;
              const table = [];
              for (let i = 0; i < rows; i++) {
                const row = [];
                for (let j = 0; j < columns; j++) {
                  row.push(createElement("td", `-${candidates[index]}`));
                  index++;
                }
                table.push(createElement("tr", [row]));
              }
              return createElement("table", { style: { width: "100%" } }, [
                table,
              ]);
            },
          });
        }
      }
    };
  },
};
</script>

<style>
#app {
  background-color: #000;
  color: #4af626;
  text-shadow: 0 0 5px #C8C8C8;
  font-family: "Courier Prime", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.vue-command .term-title {
  font-family: "Courier Prime", monospace;
  font-size: 0.85rem;
  color: #4af626;
  text-shadow: 0 0 5px #C8C8C8;
  margin: auto 0;
}
.vue-command,
.vue-command a,
.vue-command input,
.vue-command span,
.vue-command textarea {
  color: #4af626;
  text-shadow: 0 0 5px #C8C8C8;
  font-family: "Courier Prime", monospace;
}
.vue-command .term-cont {
  font-family: "Courier Prime", monospace;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1;
  margin-top: 10px;
  background-color: #000;
}
body {
  margin: 0px;
  background-color: #000;
}
.vue-command .term-bar {
  background: #000;
  border-bottom: 1px solid #252525;
  display: block;
  height: 32px;
  justify-content: center;
  top: 0;
  width: 100%;
  display: flex;
}
.vue-command .term-std {
  background: #000;
  display: block;
  flex-direction: column;
  width: 100%;
  border: 0 solid #000;
}
a,
u {
  text-decoration: none;
}
.ace {
  white-space: pre;
}

@keyframes flicker {
  0% {
  opacity: 0.27861;
  }
  5% {
  opacity: 0.34769;
  }
  10% {
  opacity: 0.23604;
  }
  15% {
  opacity: 0.90626;
  }
  20% {
  opacity: 0.18128;
  }
  25% {
  opacity: 0.83891;
  }
  30% {
  opacity: 0.65583;
  }
  35% {
  opacity: 0.67807;
  }
  40% {
  opacity: 0.26559;
  }
  45% {
  opacity: 0.84693;
  }
  50% {
  opacity: 0.96019;
  }
  55% {
  opacity: 0.08594;
  }
  60% {
  opacity: 0.20313;
  }
  65% {
  opacity: 0.71988;
  }
  70% {
  opacity: 0.53455;
  }
  75% {
  opacity: 0.37288;
  }
  80% {
  opacity: 0.71428;
  }
  85% {
  opacity: 0.70419;
  }
  90% {
  opacity: 0.7003;
  }
  95% {
  opacity: 0.36108;
  }
  100% {
  opacity: 0.24387;
  }
}

.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}
</style>
